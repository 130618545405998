.mini-button .dx-button-content
{
    padding: 2px;
}

.mini-checkbox .dx-checkbox-container .dx-checkbox-icon {
    width: 18px;
    height: 18px;
    font-size: 14px;
}
