.SelectContainer{
    padding-top: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 2vh;
}

.toolbarContainer{
    display: flex;
    flex-direction: row ;
}

.controlContainer{
    padding-left: 20px;
    display:flex;justify-content:flex-end;align-items:center;
}