.titleContainer {
  display: flex;
  justify-content: space-between;
}

.closeButtonContainer {
  padding-top: 10px;
  right: 1vw;
  top: 4vh;
  border-radius: 30px;
  border: none;
}

.greenIcon {
  color: green;
}

.orangeIcon {
  color: orange;
}
