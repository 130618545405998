
.container{
    display: flex;
    flex-direction: column;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 5px;
  }

  .grid-item-title {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-weight: bold;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
  }


  .title-text {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    margin: 10px;
  }

  .data-text {
    font-size: 1.2em;
    text-align: center;
  }