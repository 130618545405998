.flex-container {
    /* We first create a flex layout context */
    display: flex;
  
    /* Then we define the flow direction 
       and if we allow the items to wrap 
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-flow: row wrap;
  

  }

.grid-icon-buttons {
  cursor: "pointer"; 
  text-align: right;
  width: 18px;
  height: 18px;
  color: #337ab7
}