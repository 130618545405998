.dx-datagrid .dx-column-indicators {
  float: right!important;
} 
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  text-align: left!important;
  min-width: 50px;
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell {
  padding: 0!important;
}