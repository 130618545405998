.SelectContainer{
    
    padding-top: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 2vh;
   

}


.toolbarContainer{
    display: flex;
    flex-direction: row ;
}

#container{
    display: flex;
}

@media screen and (min-width: 601px) {
    .dx-list-item-content {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .dx-list-item-content {
      font-size: 9px;
    }
  }


  .dx-selectbox-container {
    font-size: 12px;
  }
  