.stepper {
  padding: 3vh 0 !important;
}

.stepper__step {
  border: 1px solid #2b7cff !important;
  cursor: default !important;
}

.stepper__step + div > span {
  color: #0f6bff !important;
}
