.main-toolbar {
    background-color: #444444;
    padding: 5px 10px;
  }
  
  .menu-list-item-icon-container,
  .menu-toolbar-before {
    width: 60px;
    padding-right: 0 !important;
    text-align: center;
  }
  
  .menu-list-item-content {
    padding-left: 10px !important;
  }
  
  .menu-button {
    background-color: rgba(191, 191, 191, -0.15);
    border: none;
  }
  
  .panel-list {
    height: 93vh;
  }
  
  .dx-drawer-expand.dx-drawer-right .panel-list {
    float: right;
  }
  
  .panel-list .dx-list-item {
    color: #fff;
    border-top: 1px solid rgba(221, 221, 221, 0.2);
  }
  
  .panel-list .dx-list-item .dx-icon {
    color: #fff !important;
  }
  
  .options {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
  }
  
  .options-container {
    display: flex;
    align-items: center;
  }
  
  .caption {
    font-size: 18px;
    font-weight: 500;
  }
  
  .option {
    margin-top: 10px;
    display: inline-block;
    margin-right: 50px;
  }
  
  label {
    font-weight: bold;
  }
  
  #content {
    height: 100%;
    padding: 10px 20px;
  }
  
  #content h2 {
    font-size: 26px;
  }

  .MenuLink{
    color: #161616;
    margin-left: 2vw;
}
.MenuLink:hover{
    color: #070606;
    margin-left: 2vw;
}
.NavLinks{
    display: "flex"
}
.UserMenuContainer{
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.AppHeader{
    background-color: darkblue;
}

.menuItemStyle{
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
}