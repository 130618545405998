.dx-treelist {
  font: 9px verdana;
}


.dx-treelist-content {
  font: 9px verdana;
}

.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  
  clear: both;
}

.dot {
  float: left;
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
}

.linearGauge{
  width: 85%;
  height: 70%;
}

.red {
  background-color: #F59595 ;
}

.green {
  background-color: #93CC77 ;
}

.yellow {
  background-color: #FFEE94 ;
}

.dx-treelist-content .dx-treelist-table .dx-row > td, .dx-treelist-content .dx-treelist-table .dx-row > tr > td {
  vertical-align: middle!important;
}

.dx-treelist .dx-column-indicators {
  float: right!important;
}

.dx-treelist-headers .dx-treelist-table .dx-row > td {
  text-align: center!important;
}

.tooltip-text-style{
  white-space: pre-line;
}

.dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container::after, .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after, .dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
  pointer-events: none;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  background-color: white !important;
  color: #fff;
  content: "!";
  border-radius: 50%;
}

.icon-container {
  display: flex;
  justify-content: center;
}

.increasing-qty {
  color: #37940b;
}

.decreasing-qty {
  color: #f5564a;
}

.equal-qty {
  color: #b7b7b7;
}
.dx-treelist-nowrap .dx-header-row .dx-treelist-text-content {  
  white-space: normal!important;  
}