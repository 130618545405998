#chart-demo {
  width: 100%;
}

#rectangleNeedle,
#twoColorNeedle,
#triangleNeedle,
#rangebar,
#textCloud,
#triangleMarker {
  display: inline-block;
}

#rectangleNeedle,
#twoColorNeedle,
#triangleNeedle {
  width: 33%;
}

#rangebar,
#triangleMarker {
  width: 32%;
}

#textCloud {
  width: 50%;
}

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.mainContainer {
  display: -webkit-flex;
}

.componentContainer {
  width: 100%;
  text-align: center;
  display: flex;
}

.chartContainer {
  width: 60%;
  text-align: center;
  margin-top: 0%;
  margin-bottom: 0%;
  height: 180px;
}

.gridContainer {
  height: 60%;
}